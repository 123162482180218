.navbar {

  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
}

.navbar-logo {
  color: #dc4535;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-logo .navbar-image {
  width: 12rem;
  height: 5rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1rem;
  /* width: auto; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #dc4535;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #df4535;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1170px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 77vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-logo .navbar-image {
    margin-top: -1rem;
    width: 10rem;
    height: 4rem;
  }

  .nav-links {
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: black;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 30px;
    cursor: pointer;
  }

  .fa-times {
    color: #df4535;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 0.5rem;
    margin: 0rem auto 0.5rem auto;
    border-radius: 4px;
    width: 80%;
    background: #dc3545;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #dc3545;
    transition: 250ms;
  }

  .contact_btn {
    display: none;
  }
}