.banner {
  width: 100%;
  height: 89vh;
  position: relative;
  overflow: hidden;
}

.slider {
  width: 100%;
  height: 89vh;
  position: absolute;
  animation-name: changeImage;
  animation-direction: alternate-reverse;
  animation-duration: 20s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  background-image: url('../../images/03.jpg');
  background-position: center, center;
  background-size: cover;
  top: 0;
}

.banner_changes {
  color: black;
  text-shadow: 1px 1px #fff;
}


@keyframes changeImage {
  0% {
    background-image: url('../../images/4.jpg');
  }

  25% {
    background-image: url('../../images/18.jpg');
  }

  50% {
    background-image: url('../../images/19.jpg');
  }

  75% {
    background-image: url('../../images/03.jpg');
  }

  100% {
    background-image: url('../../images/4.jpg');
  }
}

.overlay {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  top: 0;
  position: absolute;
}

.content {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px #000000;
}

.content h1 {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  color: #df4535;
}

.content p {
  margin: 5px 0 1rem;
  font-size: 2rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
}

.Quote_btn {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: 1px solid #dc3545;
  padding: 8px 1.2rem;
  font-size: 1.2rem;
  background: #dc3545;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  text-shadow: none;
}

.Quote_btn:hover {
  border: 1px solid #fff;
  background: transparent;
  transition: 1s;
}

.companyGoals {
  list-style: none;
  font-size: 2.5rem;
  text-align: left;
  margin-bottom: 2rem;
}

.companyGoals li {
  font-size: 1.2rem;
  font-weight: 500;
}

.companyGoals li i {
  color: green;
  padding-right: 1rem;

}

@media(max-width:700px) {
  .content h1 {
    font-size: 40px;
  }

  .content p {
    font-size: 20px;
  }

}

/* ############################### Client Section CSS ############################### */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px*7));
  }

}

.client-slider {
  height: auto;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  width: auto;
  padding: 2rem;
  padding-bottom: 2rem;
  background: #f2f2f2;
}

.client-slider .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: cal(250px*14);
}

.client-slider .client-slide {
  height: 150px;
  width: 250px;

}

.client-slide img {
  padding: 25px;
  height: 150px;
  width: 250px;
}

.clients_heading {
  margin: 0 0 2rem 0;
  text-align: center;
  font-size: 2.5rem;
  text-shadow: 0px 2px 2px rgb(130, 128, 128);
  text-transform: uppercase;
  color: black;
}

/* ############################ Products Section CSS ###################### */

.products-section {
  background: #f2f2f2;
  padding-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.products-section .products_heading {
  color: #000;
  text-shadow: 0px 2px 2px white;
  align-items: center;
}

.products {
  font-family: 'Raleway', sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  place-items: center;
}

.product-card {
  position: relative;
  flex: relative;
  max-width: 280px;
  height: 280px;
  margin-bottom: 25px;
  overflow: hidden;
  box-shadow: 5px 5px 20px rgb(54, 51, 51);
  border-radius: 8px;
}

.product-card img {
  /* opacity:0.6; */
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity, transform;
  min-height: 280px;
  min-width: 280px;
  height: 100%;
  width: 100%;
}

.product-card:hover img {
  transform: scale(1.8);

}

.product-card .product-details {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
}

.product-card .product-details h2 {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 40px;
  transition: 0.4s;
  transition-property: transform;
}

.product-card:hover .product-details h2 {
  transform: translateY(-30px);
}

.product-card .product-details p {
  margin: 20px 0 0 0px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity, transform;
}

.product-card:hover .product-details p {
  opacity: 1;
  transform: translateY(-30px);
}


.qbtn {
  padding: 1.1rem 2rem;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  background-color: #dc3545;
  margin-top: 3rem;
  box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.5);
}

.qbtn:hover {
  padding: 1.1rem 2rem;
  transition: all 0.3s ease-out;
  background: transparent;
  color: #4535ff;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 2px solid black;
  transition: all 0.3s ease-out;
}

/* ############################ Testimonial Section CSS ###################### */

.testimonial_section {
  padding: 0;
  margin: 0;
  margin-bottom: 8rem;
  width: 100%;
  height: auto;
  background-color: #262626;
  padding: 1rem 0;
}

.testimonial_heading {
  margin: 0 0 2rem 0;
  text-align: center;
  font-size: 2.5rem;
  color: #fff;
  text-shadow: 0px 2px 2px rgb(17, 17, 17);
  text-transform: uppercase;
}

.testimonial {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin: 3rem auto;
  grid-gap: 1.1rem;
  width: 99%;
}

.testimonial .cards {
  position: relative;
  width: 350px;
  height: auto;
  margin: 0 auto;
  background-color: #333;
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.testimonial .cards .layer {
  position: absolute;
  z-index: 1;
  top: calc(100% - 2px);
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(#03a9f4, #e91ee3);
  transition: 0.5s;
}

.testimonial .cards:hover .layer {
  top: 0;
}

.testimonial .cards .contents {
  z-index: 2;
  position: relative;
}

.testimonial .cards .contents p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #fff;
  text-align: left;
}

.testimonial .cards .contents .image,
.testimonial .cards .contents .customer2,
.testimonial .cards .contents .customer3 {
  width: 100px;
  height: 100px;
  background: url("../../images/users.jpeg");
  background-size: cover;
  background-position: center center;
  margin: 1rem auto;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.testimonial .cards .contents .customer2 {
  background: url("../../images/users2.jpeg");
  background-size: cover;
  background-position: center center;
}

.testimonial .cards .contents .customer3 {
  background: url("../../images/users3.jpeg");
  background-size: cover;
  background-position: center center;
}

.testimonial .cards .contents .customer4 {
  background: url("../../images/users4.jpeg");
  background-size: cover;
  background-position: center center;
  margin-top: 2rem;
}

.testimonial .cards .contents .other_changes {
  margin-top: 2rem;
}

.testimonial .cards .contents .details h2 {
  font-size: 1.05rem;
  color: #fff;
}

.testimonial .cards .contents .details h2 span {
  color: #03a9f4;
  font-size: 14px;
  transition: 0.5s;
}

.testimonial .cards:hover .contents .details h2 span {
  color: #fff;
}


/* ###################### Service Section CSS ################### */

.our_services {
  background-color: #262626;
  height: auto;
  width: 100%;
  line-height: 1.6;
  padding-bottom: 2rem;
  padding-top: 10px;
  font-family: sans-serif;
}

.our_services .service_cards_section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  place-items: center;
}

.service_card {
  color: hsl(0, 0%, 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(../../images/fire-hazard.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 6.5rem 0 0;
  max-width: 30ch;
  border-radius: 0.5rem;
  transition: transform 1000ms ease;
  overflow: hidden;
  margin-top: 1.5rem;
  height: 20rem;
  width: 350px;
}

.card1 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../images/fire-hazard.jpg);
  background-position: center center;
  background-size: cover;
}

.card2 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../images/Fire-Risk-Assessment.jpg);
  background-position: center center;
  background-size: cover;
}

.card30 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../images/system-design-engineering.jpeg);
  background-position: center center;
  background-size: cover;
}

.card40 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../images/annual-maintenance-services.jpg);
  background-position: center center;
  background-size: cover;
}

.service_card:hover {
  transform: scale(1.05);

}

.service_card_content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1));
  transform: translateY(65%);
  transition: transform 1000ms ease;
}

.service_card:hover .service_card_content {
  transform: translateY(0);

}

.service_card_title {
  position: relative;
  width: max-content;
}

.service_card_title::after {
  content: '';
  position: absolute;
  height: 4px;
  left: calc(var(--padding) * -1);
  bottom: -10px;
  width: calc(100% + var(--padding));
  background: hsl(142, 90%, 61%);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 500ms ease;
}

.service_body {
  color: rgb(255, 255, 255, 0.85);
  margin: 0.5rem 0;
}

.service_card:hover .service_card_title::after {
  transform: scaleX(1);

}

.service_button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: hsl(207, 19%, 9%);
  background-color: hsl(142, 90%, 61%);
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
}

.service_button:hover,
.service_button:focus {
  background-color: hsl(0, 0%, 100%);
}

.learn_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Learn_more_button {
  padding: 0.8rem 1rem;
  background: #dc3545;
  outline: none;
  border: none;
  margin-top: 2rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.Learn_more_button:hover {
  background-color: white;
  color: #dc3545;
}

.added-css:hover {
  background-color: black;
}





@media (max-width: 960px) {
  /* .main-section {
    flex-direction: column;
  } */

  .headerContainer h1 {
    font-size: 2.5rem;
    padding-bottom: 0rem;
  }

  .main_left {
    padding-top: 1.5rem;
  }

  .punchLine {
    font-size: 1rem;
    padding: 1rem;
  }
}