.service_main {
    background-color: rgb(18, 17, 17);
    height: auto;
    width: 100%;
}

.services_detail {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(18, 17, 17);

    margin-bottom: 9rem;
    padding-top: 5rem;
}

.about_us {
    width: 90%;
    height: auto;
    margin: 0 auto 2rem auto;
    padding: 30px 0 50px 0;
    padding-bottom: 0;
    color: black;
    background-color: #fa9805;
    box-shadow: 2px 2px 10px 2px rgba(205, 202, 202, 0.923);
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(../../../images/bg.webp);
    background-size: cover;
}

.row2 {
    display: flex;
    justify-content: space-between;
}

.about_col {
    flex-basis: 50%;
    padding: 30px 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.about_col img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.about_col .heading1 {
    font-size: 25px;
    font-weight: 600;
    color: black;
    margin-bottom: 0.9rem;
}

.about_col h1,
.more_details h1 {
    font-size: 2rem;
    color: rgb(230, 13, 13);
    text-shadow: 2px 2px 3px rgb(245, 132, 11);
    margin-bottom: 1.5rem;
}

.about_col .mentor_details,
.more_details p,
.more_details ul {
    font-size: 18px;
    color: black;
    font-weight: 400;
    margin-bottom: 1.2rem;
    word-spacing: 3px;
}

.detail_section {
    padding: 2rem 2.5rem 0 2.5rem;
}

.more_details {
    margin: 0 0 2rem 0;
    padding: 0 3rem 1rem 3rem;
}

.more_details ul li {
    list-style: none;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
}

.more_details ul li:after {
    content: '';
    height: 0.8rem;
    width: 0.8rem;
    background: #D2153A;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: .25em;
    left: 0;
}

.product-enquire-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;

}

.product-enquire-btn .enqire_more_btn {
    background-color: #dc3545;
    padding: 5px 15px;
    border-radius: 6px;
    border: none;

}

.product-enquire-btn:hover .enqire_more_btn {
    background-color: white;

}

.product-enquire-btn:hover .enquire-more {
    color: #dc3545;
}

.product-enquire-btn .enquire-more {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;


}

@media(max-width:980px) {
    .row2 {
        display: flex;
        flex-direction: column;
    }

    .detail_section {
        padding: 2rem 0.5rem 0 0.5rem;
    }
}