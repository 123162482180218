.banner_section {
    background-color: #000;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.banner_content {
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 32px; */
    padding: 10px 0;
    font-weight: 400;
    font-size: 1.1rem;
}

.banner_content span {
    font-size: 20px;
    color: #df3545;
    font-weight: bold;
}

.banner_content p {
    margin-right: 20px;
}

.banner_content p a {
    text-decoration: none;
    color: #fff;
}


@media screen and (max-width: 608px) {
    .banner_content {
        flex-direction: column;
    }

    .BannerEmail {
        margin-top: 1rem;
    }

    .banner_content p {
        margin: 0;
    }
}