.dropdown-menu {
  background: #dc3545;
  width: 250px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  text-align: center;
  z-index: 10000;

}

.dropdown-menu li {
  background: #000;
  cursor: pointer;
  border-bottom: 1px solid #000;
  color: #fff;
}

.dropdown-menu li:hover {
  background: #df4535;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

@media screen and (max-width: 1170px) {
  .dropdown-menu {
    display: none;
  }
}