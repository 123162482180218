.contact_details_section,
.Branches_office_section {
    height: auto;
    width: 100%;
    /* background: #f2f2f2; */
    text-align: center;
    padding: 2rem 0;
    text-shadow: 1px 2px 3px #424242;
}

.contact_details_section h1 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.contact_details_section p,
.Branches_office_section h1 {
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #df3545;
    text-shadow: 1px 1px 2px #ff824d;
}

.contact_detail,
.Branch_office {
    width: 80%;
    height: auto;
    /* background-color: rgba(139, 138, 138, 0.3); */
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.contact_phone,
.contact_email,
.contact_address {
    height: 100%;
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 2rem;

}

.contact_phone p,
.contact_email p,
.contact_address p {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #000;
    text-shadow: none;
    text-transform: none;
}

.contact_address p a,
.contact_email p a,
.contact_phone p a {
    text-decoration: none;
    color: #000;
}

.contact_address ion-icon,
.contact_email ion-icon,
.contact_phone ion-icon {
    font-size: 2rem;
    color: #000;
    font-style: bold;
    border: 3px solid #000;
    padding: 0.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.contact_address .contact_link,
.contact_email .contact_link,
.contact_phone .contact_link {
    margin-top: 1.5rem;
    padding: 10px;
    background-color: #df4535;
    border: 1px solid #000;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
    transition: 0.3s;
}

.contact_address .contact_link:hover,
.contact_email .contact_link:hover,
.contact_phone .contact_link:hover {
    background-color: #000;
    border: 1px solid #df4535;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
}

/* ############################## Branch Office ##################3 */

.Branch_office .contact_email,
.Branch_office .contact_phone,
.Branch_office .contact_address {
    border-right: 1px solid #5c5b5b;
    border-bottom: 1px solid #5c5b5b;
    height: 35vh;
}

.Branch_office {
    margin-bottom: 6rem;
}


/* ############################## Contact Form ########################  */

.contact_form p {
    text-align: center;
    color: white;
    margin-top: -10px;
}

.contact_form_body {
    overflow-x: hidden;
}

.contact_form_area {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: auto;
    height: auto;
    width: 100%;
    padding: 1.1rem;
    background-color: #000;
    box-sizing: border-box;
    padding: 4rem 0 7rem 0;
}


.contact_form_container {
    position: relative;
    z-index: 1000;
    width: 100%;
    height: auto;
    max-width: 1200px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    overflow: hidden;
    backdrop-filter: blur(25px);
    display: flex;
    flex-direction: row;
}

.contact_map,
.contact_form {
    height: auto;
    flex-basis: 50%;
}

.contact_map iframe {
    height: 100%;
    width: 100%;
}

.contact_form {
    padding-left: 20px;
}

.contact_form h2 {
    text-align: center;
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.row100 {
    height: auto;
    width: 100%;
}

.row100 .contact_col {
    width: 100%;
    position: relative;
    padding: 0 10px;
    margin: 2rem 0 1.1rem
}

.row100 .contact_col .input_box {
    position: relative;
    width: 100%;
    height: 2rem;
    color: #fff;
}

.row100 .contact_col .input_box input,
.row100 .contact_col .input_box textarea {
    position: absolute;
    width: 100%;
    height: auto;
    background: transparent;
    box-shadow: none;
    font-size: 1rem;
    z-index: 1;
    outline: none;
    border: none;
    color: #000;
}

.row100 .contact_col .input_box textarea,
.row100 .contact_col .input_box input {
    color: #fff;
}

.row100 .contact_col .input_box .input_line {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 2px;
    pointer-events: none;

}


.row100 .contact_col .input_box.contact_text_area {
    width: 100%;
    height: 50px;
    padding: 10px 0;
}

.row100 .contact_col .input_box input[type="submit"] {
    border: none;
    padding: 10px 40px;
    cursor: pointer;
    outline: none;
    background-color: #df4535;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

@media screen and (max-width: 960px) {

    .contact_details {
        flex-direction: column;
    }

    .contact_phone,
    .contact_email,
    .contact_address {
        flex-basis: 100%;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 760px) {

    .contact_form_container {
        flex-direction: column;
    }

    .contact_map {
        flex-basis: 100%;
    }

    .contact_map iframe {
        height: 50vh;
    }
}