.contact_btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  background-color: black;
  font-weight: bold;
  font-family: sans-serif;
}

.contact_btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #df4535;
  border-radius: 4px;
  border: 2px solid black;
  transition: all 0.3s ease-out;
}