input,
select,
button,
option {
    font-family: 'Lora', serif;
    color: black;
    font-size: 1.2rem;

}

h3 {
    margin: 0;
}

.image-holder img {
    max-width: 100%;
    height: 100%;
}

:focus {
    outline: none;
}

.Career-form {
    min-height: 100vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../images/CareerImg2.jpeg');

}

.inner-form {
    padding: 20px;
    background: #fff;
    max-width: 1050px;
    margin: auto;
    display: flex;
    margin-bottom: 10rem;
    margin-top: 5rem;
}

.inner-form .image-holder {
    width: 50%;
    background-size: 100% 100%;

}

.inner-form form {
    width: 50%;
    padding-top: 36px;
    padding-left: 45px;
    padding-right: 10px;
}

.inner-form h3 {
    text-transform: uppercase;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 28px;
    font-family: 'Roboto Slab', serif;
}

.form-group {
    display: flex;
}

.form-group input:first-child {
    margin-right: 25px;
}

.form-wrapper {
    position: relative;
}

.form-wrapper p {
    font-family: 'Lora', serif;
    font-weight: 500;
    margin-bottom: 5px;
}

.form-control {
    border: 1px solid #333;
    border: none;
    border-bottom: 1px solid #333;
    display: block;
    width: 100%;
    height: 60px;
    margin-bottom: 25px;

}

select {
    cursor: pointer;
    color: grey;
    padding-left: 20px;
}

select option[value=""][disabled] {
    display: none;
}

.career-btn {
    border: none;
    width: 164px;
    height: 51px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333;
    font-size: 1.5rem;
    color: #fff;
}

.career-btn:hover {
    background: #dc3545;
}

@media (max-width: 767px) {
    .inner-form {
        display: block;
    }

    .inner-form .image-holder {
        width: 100%;
    }

    .inner-form form {
        width: 100%;
        padding: 40px 0 30px;
    }

    .career-btn {
        margin-top: 60px;
    }
}