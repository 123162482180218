@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Nunito+Sans:wght@300;400;600;700;800&display=swap');

footer {
    position: relative;
    width: 100%;
    background-color: #000;
    min-height: 100px;
    /* padding: 20px 50px; */
}

.footer_top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer .social_icons {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}

footer .social_icons li {
    list-style: none;
}

footer .social_icons li img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

footer .social_icons li a {
    font-size: 2rem;
    color: white;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
}

footer .social_icons li a:hover {
    transform: translateY(-10px);
}

footer .AFS_logo p {
    color: #fff;
    text-align: center;
    margin-top: 15px;
    /* margin-bottom: 10px; */
    margin: 0;
    font-size: 1.1em;
}

footer .wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../images/wave2.png);
    background-size: 1000px 100px;
}

footer .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animate 4s linear infinite;
}

footer .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate_02 4s linear infinite;
}

footer .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animate 3s linear infinite;
}

footer .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

@keyframes animate {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-position-y: 0px;
    }
}

@keyframes animate_02 {
    0% {
        background-position-x: 0px;
    }

    100% {
        background-position-y: 1000px;
    }
}

.AFS_logo {
    color: #fff;
}

.AFS_logo h1 {
    font-size: 2.5rem;
    font-family: 'Bebas Neue', cursive;
    /* font-family: 'Nunito Sans', sans-serif; */
    letter-spacing: 2px;
    text-align: center;
    color: #fe3545;
}


/* ############## Footer Content Section designing ############### */

.footer_bottom {
    /* position: relative; */
    width: 100%;
    height: auto;
    padding: 50px 100px;
    background-color: #000;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.footer_bottom .footer_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
}

.footer_bottom .footer_container .footer_sec {
    margin-right: 30px;
}

.footer_bottom .footer_container .footer_sec.footer_aboutus {
    width: 35%;
}

.footer_bottom .footer_container h2 {
    position: relative;
    color: #fff;
    font-weight: 500;
    margin-bottom: 1rem;
}

.footer_bottom .footer_container h2::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 2px;
    background: #fe3545;
}

.footer_bottom .footer_container .footer_sec.footer_aboutus p {
    color: #999;
    margin-bottom: 2rem;
}

.footer_quicklinks {
    /* position: relative; */
    width: 15%;
}

.footer_quicklinks ul li {
    list-style-type: none;
    text-align: justify;
}

.footer_quicklinks ul li .footer_links {
    color: #999;
    text-decoration: none;
    margin-bottom: 5px;
    display: inline-block;

}

.footer_quicklinks ul li .footer_links:hover {
    color: #fff;
}

.footer_contact {
    width: calc(35% - 60px);
    margin-right: 0 !important;
}

.footer_contact .footer_info {
    position: relative;
}

.footer_contact .footer_info li {
    display: flex;
    margin-bottom: 1rem;
}

.footer_contact .footer_info li span:nth-child(1) {
    color: #fff;
    font-size: 1.1rem;
    margin-right: 10px;
}

.footer_contact .footer_info li span {
    color: #999;
}

.footer_contact .footer_info li a {
    color: #999;
    text-decoration: none;
}

.footer_contact .footer_info li a:hover {
    color: #fff;
}

.footer_copyright {
    color: #999;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.footer_copyright a {
    color: #999;
    font-size: 1rem;
    /* line-height: 1rem; */
}

.footer_copyright a:hover {
    color: #fff;
}

@media (max-width: 911px) {
    .footer_bottom {
        padding: 40px;
    }

    .footer_bottom .footer_container {
        flex-direction: column;
    }

    .footer_bottom .footer_container .footer_sec {
        margin-right: 0;
        margin-bottom: 40px;
    }

    .footer_quicklinks {
        width: 100%;
    }

    .footer_bottom .footer_container .footer_sec.footer_aboutus {
        width: 100%;
    }

    .footer_contact {
        width: 100%;
    }

    .footer_copyright {
        padding: 0.5rem 2rem;
    }
}

.download-btn {
    background-color: #df4535;
    color: white;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.5s;
    /* margin-top: 5rem; */
}

.download-btn:hover {
    background-color: white;
    color: #fe3545;
    border: 2px solid #fe3545;
}