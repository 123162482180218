#container {
  /* z-index: -1; */
  overflow: hidden;
  background: #000;
  padding: 2rem;

}


.service {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  position: relative;
}

.service::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background: linear-gradient(to right, #f00, #f00, #0f0, #0ff, #ff0, #0ff);
  mix-blend-mode: color;
  pointer-events: none;
}

.service video {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* height: 100%; */
}

.service h1 {
  font-family: "Arima Madurai", cursive;
  color: white;
  font-size: 5rem;
  letter-spacing: 3px;
  /* text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8); */
  position: absolute;
  /* z-index: 3; */
  letter-spacing: 10px;
}

.service h1 span {
  opacity: 0;
  display: inline-block;
  animation: smoke_animation 1s linear forwards;
}

@keyframes smoke_animation {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
    filter: blur(10px);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    filter: blur(0);
  }
}

h1 span:nth-child(1) {
  animation-delay: 0.5s;
}

h1 span:nth-child(2) {
  animation-delay: 0.75s;
}

h1 span:nth-child(3) {
  animation-delay: 1s;
}

h1 span:nth-child(4) {
  animation-delay: 1.5s;
}

h1 span:nth-child(5) {
  animation-delay: 1.75s;
}

h1 span:nth-child(6) {
  animation-delay: 2s;
}

h1 span:nth-child(7) {
  animation-delay: 2.25s;
}

h1 span:nth-child(8) {
  animation-delay: 2.5s;
}

h1 span:nth-child(9) {
  animation-delay: 2.75s;
}

h1 span:nth-child(10) {
  animation-delay: 3s;
}

h1 span:nth-child(11) {
  animation-delay: 3.25s;
}

/* ############# CARDs section CSS ############# */

.cards_section {
  width: 95%;
  /* overflow-x: hidden; */
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
  grid-auto-flow: row;
  padding: 3rem 0rem;
  margin-bottom: 3rem;
}

.card {
  width: 300px;
  height: 400px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  transition: 1s;
  margin: auto;
  margin-bottom: 2.5rem;
}

.card1 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/fire-hazard.jpg);
  background-position: center center;
  background-size: cover;
}

.card2 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/Fire-Risk-Assessment.jpg);
  background-position: center center;
  background-size: cover;
}

.card3 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/Consultancy-Training.jpg);
  background-position: center center;
  background-size: cover;
}

.card4 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/e-procurement-Ans-Supply.png);
  background-position: center center;
  background-size: cover;
}

.card5 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/system-design-engineering.jpeg);
  background-position: center center;
  background-size: cover;
}

.card6 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/AMC.jpg);
  background-position: center center;
  background-size: cover;
}

.card7 {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../../../images/annual-maintenance-services.jpg);
  background-position: center center;
  background-size: cover;
}


.card-img {
  position: absolute;
  height: 100%;
  min-width: 100%;
  top: 50%;
  left: 50%;
  transition: 1s;
  transform: translate(-50%, -50%);
}

.card:hover {
  transform: translateY(-20px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.card:hover.card-img {
  height: 110%;
}

.card_content {
  width: 92%;
  height: 95%;
  overflow: hidden;
  border-radius: 20px;
  border: 3.5px solid white;
  text-align: center;
  padding: 20px 15px 30px 15px;
  position: relative;
  transition: 0.5s;
  opacity: 0;
}

.card_content .service_heading {
  opacity: 1;
}

.card:hover .card_content {
  opacity: 1;
}


.title {
  position: relative;
  text-transform: capitalize;
  font-size: 40px;
  margin-bottom: 10px;
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
}

.info {
  z-index: 2;
  position: relative;
  opacity: 0;
  transition: 0.5s;
  margin-bottom: 20px;
}

.read_more {
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
  position: relative;
  text-decoration: none;
  background-color: black;
  padding: 10px;
  color: white;
}

.card_content span {
  position: absolute;
  width: 25%;
  height: 0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 1s;
}

.card_content span:nth-child(2) {
  left: 25%;
}

.card_content span:nth-child(3) {
  left: 50%;
}

.card_content span:nth-child(4) {
  left: 75%;
}

.card:hover .card_content span:nth-child(1) {
  height: 85%;
  transition-delay: 0.15s;
}

.card:hover .card_content span:nth-child(2) {
  height: 75%;
  transition-delay: 0.45s;
}

.card:hover .card_content span:nth-child(3) {
  height: 79%;
  transition-delay: 0.75s;
}

.card:hover .card_content span:nth-child(4) {
  height: 90%;
  transition-delay: 1s;
}

.card:hover .title,
.card:hover .info {
  opacity: 1;
  transition-delay: 1.5s;
}

.card:hover .read_more {
  /* display: block; */
  opacity: 1;
  transition-delay: 2s;
}



@media (max-width: 650px) {
  .service h1 {
    font-size: 2.5rem;
    letter-spacing: 0;
  }
}