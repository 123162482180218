.scroll-to-top {
    position: fixed;
    bottom: 20px;
    /* left: 50%; */
    right: 50px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #df4535;
    color: #fff;
    z-index: 1000;
}

.scroll-to-top .icon {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}