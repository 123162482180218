.about-us-section {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-heading,
.vision-heading {
  /* font-family: 'Stoke', serif; */
  color: black;
  text-align: center;
  font-size: 2.2rem;
  margin: 30px 0 10px 0;
}

.about-intro {
  /* background-color: #df3545; */
  width: 45%;
  height: auto;
  margin: 1rem auto;
  padding: 0 0 10px 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.about-content {
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
  margin-bottom: 0.3rem;
  word-spacing: 3px;
  padding: 1rem 1rem 0 2.5rem;
  /* font-family: 'Lobster', cursive; */
  /* text-shadow: 2px 2px 3px white; */


}

.about-content span {
  font-weight: 900;
}

.content-border {
  border: 2px solid white;
  width: 95%;
  border-radius: 15px;
  padding-bottom: 20px;
  padding-top: 0px;

}



.vision-heading {
  font-family: 'Stoke', serif;
  color: black;
  text-align: center;
  margin: 18px 0px 0 10px 0;
  font-size: 2.2rem;

}


.content-border img {
  width: 220px;
  height: 220px;
  /* border-radius: 50; */
  display: flex;
  margin: 20px auto;
}

@media screen and (max-width: 930px) {
  .about-us-section {
    flex-direction: column;
  }

  .about-intro {
    width: 90%;
  }

  .about-content {
    font-size: 1rem;
  }

  .content-border {
    border: none;
    padding-bottom: none;
  }

  .content-border img {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .about-content {
    font-size: 1rem;
    /* padding: 1rem 1rem 0 1rem; */
  }

  .content-border {
    width: 98%;
  }

  .vision-heading {
    margin: 30px 0 10px 0;
  }

}

/* ############################## Core Values ########################  */
.core-container {
  font-family: 'Raleway', sans-serif;
  background: #e1e2e7;
  /* margin: 0 0 100px 0; */
  height: auto;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.core_header {
  font-size: 2.8rem;
  font-weight: bold;
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  text-shadow: 1px 2px 3px #969494;
}

.core-row {
  font-family: 'Raleway', sans-serif;
  width: 98%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
}

.core-image {
  position: relative;
  flex: relative;
  /* max-width: 300px; */
  height: 280px;
  /* margin:25px; */
  width: 18%;
  margin-bottom: 50px;
  text-align: center;
  align-self: normal;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgb(54, 51, 51);
}

.core-image img {
  /* opacity:0.6; */
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity, transform;
  /* min-height:400px;
    min-width:400px; */
  height: 100%;
  width: 100%;
}

.core-image:hover img {
  /* opacity:1; */
  transform: scale(1.2);

}

.core-image .core-details {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
}

.core-image .core-details h2 {
  text-align: center;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 60px;
  transition: 0.4s;
  transition-property: transform;
}

.core-image:hover .core-details h2 {
  transform: translateY(-30px);
}

.core-image .core-details p {
  margin: 20px 30px 0 30px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity, transform;
}

.core-image:hover .core-details p {
  opacity: 1;
  transform: translateY(-30px);
}

@media screen and (max-width:1315px) {
  .core-image {
    width: 48%;
    height: 220px;
  }

  .core-row {
    width: 95%;
  }

  .core-image .core-details h2 {
    font-size: 1.8rem;
  }

  .core-image .core-details p {
    font-size: 1rem;
    margin: 10px 5px 0 5px;
  }


}

@media screen and (max-width:528px) {
  .core-image {
    width: 48%;
    height: 200px;
  }

  .core-row {
    width: 92%;
  }

  .core-image .core-details h2 {
    font-size: 1.3rem;
  }

  .core-image p {
    font-size: 1rem;
    margin: 10px 5px 0 5px;
  }
}



/* ###################### Certifications ###################### */
.certifications {
  margin-top: 2rem;
  padding: 1rem 0;
  height: auto;
  width: 100%;
  /* background-color: rgb(26, 25, 25); */
  background-color: #f2f2f2;
  padding-bottom: 7rem;
  /* color: #fff; */
}

.certificate_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
}

.certificate_section .startup_certificate,
.certificate_section .iso_certificate {
  height: 100%;
  flex-basis: 50%;
  padding: 2rem;
}

.certificate_section .startup_certificate img,
.certificate_section .iso_certificate img {
  height: auto;
  width: 100%;
  /* box-shadow: 0px 3px 10px rgba(255, 255, 255, 1); */
  border: 15px solid #190201f0;
}

.certificate_section .iso_certificate h1 {
  font-size: 3rem;
  text-align: center;
}

.certificate_section .iso_certificate p {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.3rem;
}


.certificate_section {
  display: flex;
  flex-direction: column;
}

.section_two {
  flex-direction: column-reverse;
}

.certificate_section .startup_certificate,
.certificate_section .iso_certificate {
  height: 100%;
  flex-basis: 50%;
  padding: 1rem 2rem;
}

.certificate_heading {
  margin-bottom: 0;
  font-size: 3rem;
  text-align: center;
}

@media screen and (max-width: 608px) {
  .certificate_section .iso_certificate h1 {
    font-size: 2rem;
  }

  .certificate_section .iso_certificate p {
    font-size: 1rem;
  }
}

/* #################### Team Section ######################### */
.team_section_container {
  margin: 0;
  padding: 0;
  background-color: #000;
  font-family: sans-serif;
  padding: 2rem 0;
}

.team-container {
  width: 100%;
  height: auto;

}

.team_heading {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
}

.text {
  color: #df4535;
  text-shadow: 0 2px 0 rgba(235, 125, 14, 0.5);
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;

}

.team_row {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  margin-top: 1.1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-items: center; */
  justify-content: center;
}



.profile-card_section {
  background-color: #ffffff;
  width: 20rem;
  height: 20rem;
  margin: 20px 10px 10px 10px;
  box-shadow: 2px 2px 10px rgba(125, 123, 123, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 2rem;
}

.company_images {
  display: flex;
  justify-content: center;
  align-items: center;
}


.profile-content_card {
  padding: 15px;
}

.profile-image_card img {
  width: 9rem;
  height: 9rem;
  background: transparent;
}

.images_logos {
  height: 3rem;
  width: 3rem;
  margin: 1.5rem 0 0 0.5rem;
  align-items: center;
}

.profile-image_card {
  text-align: center;

}

.member_desc {
  text-align: center;
}

.member_desc h2 {
  color: rgb(12, 172, 150);
  font-size: 1.2rem;
  margin-top: 1rem;
}

.member_desc p {
  font-size: 13px;
  line-height: 1.3em;
  margin-top: 10px;
  color: #000000;

}

@media (max-width: 902px) {
  .profile-card_section {
    width: 15rem;
  }
}

@media(max-width: 768px) {
  .team_row {
    width: 100%;
  }
}

@media (max-width: 608px) {
  .profile-card_section {
    width: 13rem;
    height: 21rem;
  }
}

@media(max-width: 540px) {
  .profile-card_section {
    width: 12rem;
    height: 21rem;
    margin-right: 10px;
  }
}

@media(max-width: 468px) {
  .profile-card_section {
    width: 10rem;
    height: 21rem;
    margin-left: 10px;
  }
}


/* ############## Brochure Download ######### */
.brochure_section {
  width: 100%;
  height: auto;
  text-align: center;
  /* margin: 0 0 2rem 0; */
  background-color: #f2f2f2;
  padding: 2rem;
}

.brochure_section p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: capitalize;
}

.download-btn {
  background-color: #df4535;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.5s;
}

.download-btn:hover {
  background-color: white;
  color: #df4535;
  border: 2px solid #df4535;
}