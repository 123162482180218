@import url('https://fonts.googleapis.com/css2?family=Gentium+Plus&family=Merriweather:wght@900&family=Raleway:wght@300;400;500;600;700;800&display=swap');



#container {
  /* z-index: -1; */
  overflow: hidden;
  background: #000;
  padding: 2rem;

}


.service {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  position: relative;
}

.service::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background: linear-gradient(to right, #f00, #f00, #0f0, #0ff, #ff0, #0ff);
  mix-blend-mode: color;
  pointer-events: none;
}

.service video {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* height: 100%; */
}

.service h1 {
  font-family: "Arima Madurai", cursive;
  color: white;
  font-size: 5rem;
  letter-spacing: 3px;
  /* text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8); */
  position: absolute;
  /* z-index: 3; */
  letter-spacing: 10px;
}

.service h1 span {
  opacity: 0;
  display: inline-block;
  animation: smoke_animation 1s linear forwards;
}

@keyframes smoke_animation {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
    filter: blur(10px);
  }

  100% {
    opacity: 1;
    transform: rotateY(0deg);
    filter: blur(0);
  }
}

h1 span:nth-child(1) {
  animation-delay: 0.5s;
}

h1 span:nth-child(2) {
  animation-delay: 0.75s;
}

h1 span:nth-child(3) {
  animation-delay: 1s;
}

h1 span:nth-child(4) {
  animation-delay: 1.5s;
}

h1 span:nth-child(5) {
  animation-delay: 1.75s;
}

h1 span:nth-child(6) {
  animation-delay: 2s;
}

h1 span:nth-child(7) {
  animation-delay: 2.25s;
}

h1 span:nth-child(8) {
  animation-delay: 2.5s;
}

h1 span:nth-child(9) {
  animation-delay: 2.75s;
}

h1 span:nth-child(10) {
  animation-delay: 3s;
}

h1 span:nth-child(11) {
  animation-delay: 3.25s;
}

.industries-container {
  height: auto;
  font-family: 'Raleway', sans-serif;
  background: #c5c6c9;
  padding-top: 1rem;
  margin-bottom: 7rem;
  padding-bottom: 2rem;
}

.industries-row {
  font-family: 'Raleway', sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  place-items: center;

}

.industries-image {
  position: relative;
  flex: relative;
  width: 300px;
  height: 350px;
  display: grid;
  margin: 1.5rem;
  overflow: hidden;
  box-shadow: 5px 5px 20px rgb(54, 51, 51);
}

.industries-image img {
  /* opacity:0.6; */
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity, transform;
  min-height: 400px;
  min-width: 400px;
  height: 100%;
  width: 100%;
}

.industries-image:hover img {
  /* opacity:1; */
  transform: scale(1.8);

}

.industries-image .details {
  /* z-index: 1; */
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
}

.industries-image .details h2 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 40px;
  transition: 0.4s;
  transition-property: transform;
}

.industries-image:hover .details h2 {
  transform: translateY(-30px);
}

.industries-image .details p {
  margin: 20px 30px 0 30px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity, transform;
}

.industries-image:hover .details p {
  opacity: 1;
  transform: translateY(-30px);
}

.more {
  justify-content: center;
  align-items: center;

}

.more .contact_btn {
  background-color: #dc3545;

}

.more:hover .btn {
  background-color: white;
}

.more:hover .read-more {
  color: #dc3545;
}

.more .read-more {
  color: white;
  text-decoration: none;
}

@media(max-width: 1080px) {
  .industries-image {
    flex: 100%;
    max-width: 480px;
  }

  .service h1 {
    font-size: 2.5rem;
    letter-spacing: 0;
  }
}