.clients-section {
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    height: auto;
    background-color: #fcf8f8;
}

.comment-line {
    padding: 2rem;
    font-size: 1rem;
    color: grey;
    font-weight: 100;
    font-family: sans-serif;
    text-align: center;
}

.clients-row {
    display: grid;
    width: 85%;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    place-items: center;
    margin: 0 auto;
}

.client img {
    padding: 25px;
    height: 160px;
    width: 260px;
    margin-top: 3rem;
    border: 10px solid #ececec;
    box-shadow: 1px 7px 7px #cfcfcf;
}

@media screen and (max-width:1460px) {
    .clients-row {
        width: 90%;
    }
}

@media screen and (max-width:1199px) {
    .clients-row {
        width: 90%;
    }

    .clients-row {
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }

    .client img {
        padding: 25px;
        height: 150px;
        width: 230px;

    }
}

@media screen and (max-width: 800px) {
    .clients-row {
        width: 95%;
    }

    .clients-row {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .client img {
        padding: 25px;
        height: 120px;
        width: 180px;

    }
}

@media screen and (max-width:630px) {
    .clients-row {
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }

    .client img {
        padding: 25px;
        height: 120px;
        width: 150px;
    }
}